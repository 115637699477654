import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Promotion from "./pages/Promotion";
import LiveCasino from "./pages/LiveCasino";
import Sports from "./pages/Sports";
import Dashboard from "./pages/Dashboard";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import RequestReport from "./pages/RequestReport";
import GameReport from "./pages/GameReport";
import Vip from "./pages/Vip";
import App from "./App";
import "./style/style.css";
import Header from "./Header";
import Footer from "./Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Header />
    <div id="content" className="site-content">
    <div id="primary" className="content-area">
    <main id="main" className="site-main">
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/live-casino" element={<LiveCasino />} />
        <Route path="/sportsbook" element={<Sports />} />
        <Route path="account/dashboard" element={<Dashboard />} />
        <Route path="account/deposit" element={<Deposit />} />
        <Route path="account/withdraw" element={<Withdraw />} />
        <Route path="account/request-report" element={<RequestReport />} />
        <Route path="account/game-report" element={<GameReport />} />
        <Route path="/vip" element={<Vip />} />
      </Routes>
    </Router>
    </main>
    </div>
    </div>
    <Footer />
  </React.StrictMode>
);
