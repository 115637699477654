function Header() {
  return (
    <>
        <header id="masthead" className="site-header has-logo has-menu">
            <div className="sticky-navigation desktop-view">
                <div className="navigation-fixed-container">
                    <a href="/"><img className="website-logo" src="/images/sg8-casino-logo-1.webp" alt="official sg8 logo" /></a>
                    <div className="user-information">
                        <div className="userwrapper">
                            <span className="userID">SG8PNR750595</span>
                            <span className="userBalance">10,000.00</span>
                        </div>
                        <div className="c2a-depo-withdraw">
                            <a  href="/account/deposit/" className="depo-btn">Deposit</a>
                            <a  href="/account/withdraw/" className="withdraw-btn">Withdraw</a>
                        </div>
                        <div className="c2a-landingpage" style={{display: `none`}}>
                            <button className="login-btn">Login</button>
                            <button className="reg-btn">Register</button>
                        </div>
                    </div>
                    <div className="navigation-menu-list">
                        <div className="primary-menu-list">
                            <div className="menu" style={{backgroundImage: `url(/images/slots-navigation.webp)`}}><a href="/slots/rtg/"><span>Slots</span></a></div>
                            <div className="menu" style={{backgroundImage: `url(/images/ld-navigation.webp)`}}><a href="/live-casino/"><span>Live Dealer</span></a></div>
                            <div className="menu" style={{backgroundImage: `url(/images/sportsbook-navigation.webp)`}}><a href="/sportsbook/"><span>Sportsbook</span></a></div>
                        </div>
                        <div className="secondary-menu-list">
                            <ul>
                                <li><a href="/promotion/">Promotion</a></li>
                                <li><a href="/vip/">VIP</a></li>
                                <li><a href="/account/dashboard/">Account</a></li>
                            </ul>
                        </div>
                        <div className="navarea-promotions">
                            <img src="/images/depositbonus-with-ring.webp" alt=""/>
                            <a href="#"><span>FULL T&C</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-header-container mobile-view">
                <div className="mobile-website-logo">
                    <img className="website-logo" src="/images/sg8-casino-logo-1.webp" alt="official sg8 logo" />
                </div>
            </div>
        </header>
    </>
  )
}

export default Header
