import BaDepositDropdown from "../snippets/BaDepositDropdown"
import React, { useState } from 'react';
function Deposit() {
    const [visibleId, setVisibleId] = useState('payCoolsForm'); // State to track which element should be visible
  
    // Function to handle click event on deposit links
    const handleDepositClick = (id) => {
      setVisibleId(id); // Update the state to show the corresponding element
    };

    return (
      <>
        <div className="account-container deposit-container">
          <div className="smallbanner"><img src="/images/gcashviamayathinbanner.jpg" alt="Gcash via Maya"/></div>
          <h2>Deposit Request</h2>
          <div className="depositnav-nav nav-tabs">
              <div id="payCoolsBtn" classNameName={`${visibleId === 'payCoolsForm' ? 'active' : ''}`}><a onClick={() => handleDepositClick('payCoolsForm')}>QRPH (New)</a></div>
              <div id="payMayaBtn" classNameName={`${visibleId === 'payMayaForm' ? 'active' : ''}`}><a onClick={() => handleDepositClick('payMayaForm')}>PayMaya</a></div>
              <div id="fortunePayBtn" classNameName={`${visibleId === 'fortunePayForm' ? 'active' : ''}`}><a onClick={() => handleDepositClick('fortunePayForm')}>FortunePay</a></div>
              <div id="dragonPayBtn" classNameName={`${visibleId === 'dragonPayForm' ? 'active' : ''}`}><a onClick={() => handleDepositClick('dragonPayForm')}>DragonPay</a></div>
          </div>
          <div className="deposit all-form-container">
          {/* Conditional rendering to show the T&C content based on the state */}
          {visibleId === 'payCoolsForm' && <form id="payCoolsForm"><input name="cmd" type="hidden" value="deposit-paycools" /><input name="u" type="hidden" value="" /><input name="returnUrl" type="hidden" value="" />
                      <div style={{textAlign: "center"}}>
                          <div className="notice">
                              <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
                          </div>
                          <div className="row">
                              <div className="hLabel">Deposit Amount</div>
                              <div className="input-container"><input id="paycools-amount" className="form-control txt-amount" autocomplete="off" max="30000" maxlength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:50,000" /></div>
                          </div>
                          
                          <div className="row">
                              <div className="hLabel">Bonus</div>
                              <div className="input-container"><select name="bonusid" id="bonusListNonBTC" className="form-control bonusList"><option value="-1">-- Select Bonus-- </option></select></div>
                          </div>
                          <BaDepositDropdown />
                          <div className="row">
                              <div style={{textAlign: "center"}}>
                                  <div id="paycools-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="paycools-alert"></div></div>
                                  <button id="paycools-submit" className="yellow-btn" type="button">SUBMIT</button>
                              </div>
                          </div>  
                      </div>
                      <div style={{clear: "both"}}></div>
                  </form>}
          {visibleId === 'payMayaForm' && <form id="payMayaForm" style={{display: "block"}}><input name="cmd" type="hidden" value="deposit-paymaya" /><input name="u" type="hidden" value="" /><input name="returnUrl" type="hidden" value="" />
              <div style={{textAlign: "center"}}>
                <div className="notice">
                  <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
                </div>
                <div className="row">
                  <div className="hLabel">Deposit Amount</div>
                  <div className="input-container"><input id="paymaya-amount" className="form-control txt-amount" autocomplete="off" max="30000" maxlength="10" min="500" name="amt" required="required" type="text" placeholder="" /></div>
                </div>
                
                <div className="row">
                  <div className="hLabel">Bonus</div>
                  <div className="input-container"><select name="bonusid" id="bonusListNonBTC" className="form-control bonusList"><option value="-1">-- Select Bonus-- </option></select></div>
                </div>
                <BaDepositDropdown />
                <div className="row">
                  <div style={{textAlign: "center"}}>
                    <div id="paymaya-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="paymaya-alert"></div></div>
                    <button id="paymaya-submit" className="yellow-btn" type="button">SUBMIT</button>
                  </div>
                </div>  
              </div>
              <div style={{clear: "both"}}></div>
            </form>}
            {visibleId === 'fortunePayForm' && <form id="fortunePayForm" style={{display: "block"}}><input name="cmd" type="hidden" value="deposit-fortunepay" /><input name="u" type="hidden" value="" /><input name="returnUrl" type="hidden" value="" />
                  <div style={{textAlign: "center"}}>
                      <div className="notice">
                          <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
                      </div>
                      <div className="row">
                          <div className="hLabel">Deposit Amount</div>
                  <div className="input-container"><input id="fortunepay-amount" className="form-control txt-amount" autocomplete="off" max="30000" maxlength="10" min="500" name="amt" required="required" type="text" placeholder="" /></div>
                      </div>
                      
                      <div className="row">
                          <div className="hLabel">Bonus</div>
                          <div className="input-container"><select name="bonusid" id="bonusListNonBTC" className="form-control bonusList"><option value="-1">-- Select Bonus-- </option></select></div>
                      </div>
                      <BaDepositDropdown />
                      <div className="row">
                          <div style={{textAlign: "center"}}>
                              <div id="fortunepay-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="fortunepay-alert"></div></div>
                              <button id="fortunepay-submit" className="yellow-btn" type="button">SUBMIT</button>
                          </div>
                      </div>  
                  </div>
                  <div style={{clear: "both"}}></div>
              </form>}
              {visibleId === 'dragonPayForm' && <form id="dragonPayForm" style={{display: "block"}}><input name="cmd" type="hidden" value="deposit-dragonpay" /><input name="u" type="hidden" value="" /><input name="returnUrl" type="hidden" value="" />
              <div style={{textAlign: "center"}}>
                <div className="notice">
                  <div><strong>Notice: </strong>Please contact us if game credits are not credited to your account within 10 minutes upon successful payment.</div>
                </div>
                  
                <div className="row">
                  <div className="hLabel">Payment Options</div>
                  <div className="input-container"><select id="dragonPayProcId" name="procId" className="formSelect">
                    <option value="">-- Select Option --</option>
                  </select></div>
                </div>
                  
                <div className="row">
                  <div className="hLabel">Deposit Amount</div>
                  <div className="input-container"><input id="dragonpay-amount" className="form-control txt-amount" autocomplete="off" max="30000" maxlength="10" min="1" name="amt" required="required" type="text" placeholder="" /></div>
                </div>
                
                <div className="row">
                  <div className="hLabel">Bonus</div>
                  <div className="input-container"><select name="bonusid" id="bonusListNonBTC" className="form-control bonusList"><option value="-1">-- Select Bonus-- </option></select></div>
                </div>
                <BaDepositDropdown />
                    
                <div className="row">
                  <div style={{textAlign: "center"}}>
                    <div id="dragonpay-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="dragonpay-alert"></div></div>
                    <button id="dragonpay-submit" className="yellow-btn" type="button">SUBMIT</button>
                  </div>
                </div>  
              </div>
              <div style={{clear: "both"}}></div>
            </form>}
          </div>
        </div>
      </>
  )
}

export default Deposit