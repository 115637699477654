import "../style/accountStyle.css";
import AccountSelection from "../snippets/AccountSelection"
function RequestReport() {
    return (
      <>
        <div class="account-content report-page">
          <AccountSelection />
          <div class="welcome-user">
            <span class="welcome">Welcome, </span><span class="user-name">...</span><button id="verifyAccountPUM" class="popmake-4154 pum-trigger">Verify</button><button id="uploadPhotoPUM" class="popmake-4137 pum-trigger">Upload Photo</button>
          </div>
          <div class="account-container tier-info">
            
              <div class="report-info">
                <h2>Funds Request Report</h2>
                <div class="title-line"></div>
                <div>
                  <p class="referral-text">View your consolidated report within the selected date range. Report reflects data recorded from 6AM (GMT+8) on the selected date to 6AM (GMT+8) the following day.</p>
                </div>
              </div>
              
              <div class="table-fields">
                <form id="rp-requestForm">
                  <div class="from-to-type-container">
                    <div class="field-row">
                      <label>From:</label>
                      <input type="date" name="fd" />
                    </div>
                    <div class="field-row">
                      <label>To:</label>
                      <input type="date" name="td" />
                    </div>
                    <div class="field-row">
                      <label>Type:</label>
                      <select name="status"><option value="-1">Pending</option> <option value="1">Success</option><option value="0">Failed</option><option value="-2">All</option></select>
                    </div>
                    <div class="field-row">
                      <button id="rp-searchBtn" className="yellow-btn" type="button"><i class="fa fa-search"></i>SEARCH</button>
                    </div>
                  </div>
                </form>
                {/* <div class="search-container">
                  <div class="field-row">
                    <input type="text" placeholder="Search" name="">
                  </div>
                </div> */}
              </div>
              <div class="summary">
                <h3>Summary</h3>
                <div>
                <table>
                  <tr>
                    <th>Date</th>
                    <th>Type of Ref. No.</th>
                    <th>Bank</th>
                    <th>SG8 Bank</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                  <tr id="rp-reportList"></tr>
                </table>
                </div>
              </div>
            </div>
        </div>

        <div class="main-content-wrapper">
          <div class="text-content-area">
              <h2 class="text-content-title">Who is SG8 Casino?</h2>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <span class="text-content-sub-title">How to Register at SG8 Casino?</span>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <span class="text-content-sub-title">How to Register at SG8 Casino?</span>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <div></div>
          </div>
        </div>
      </>
)
}

export default RequestReport