
import "../style/accountStyle.css";
import AccountSelection from "../snippets/AccountSelection"
function Dashboard() {
  
    return (
      <>
        <div className="account-content dashboard">
          <AccountSelection />
          <div className="welcome-user">
            <span className="welcome">Welcome, </span><span className="user-name">...</span><button id="verifyAccountPUM" className="popmake-4154 pum-trigger">Verify</button><button id="uploadPhotoPUM" className="popmake-4137 pum-trigger">Upload Photo</button>
          </div>
          <div className="account-right-container">
            <div className="featured-icons">
                <div className="icons-container">
                    <a href="/slots/rtg/" className="featured-item" style={{textDecoration: "none"}}><img src="/images/slot.png"/><br/><span>SLOTS</span></a>
                    <a href="/live-casino/" className="featured-item" style={{textDecoration: "none"}}><img src="/images/live-casino.png"/><br/><span>LIVE CASINO</span></a>
                    <a href="/sportsbook/" className="featured-item" style={{textDecoration: "none"}}><img src="/images/sports.png"/><br/><span>SPORTSBOOK</span></a>
                </div>
            </div>
            <div className="recent-games">
              <h3>Recent Games</h3>
              <div className="title-line"></div>
              <div id="recentGamesList"></div>
            </div>
          </div>
          <div className="vip-info-container">
            <div className="tier-info">
              <div className="account-collapsible">
                <ul>
                  <li>
                    <h3>Change Password</h3>
                    <div className="collapse-content">
                      <div className="notice">Notice: Password needs to be between 6 - 8 characters.</div>
                      <form id="changePasswordForm">
                        <input name="cmd" type="hidden" value="CreatePlayer" />
                        <div>
                          <div className="field-row">
                            <label>Current Password</label>
                            <div className="field-container">
                              <input type="password" name="" id="currentPwd" maxlength="8" autocomplete="off"/>
                            </div>
                          </div>
                          <div className="field-row">
                            <label>New Password</label>
                            <div className="field-container">
                              <input type="password" name="pwd" id="newPwd" maxlength="8" autocomplete="off"/>
                            </div>
                          </div>
                          <div className="field-row">
                            <label>Confirm New Password</label>
                            <div className="field-container">
                              <input type="password" name="" id="newPwd2" maxlength="8" autocomplete="off"/>
                            </div>
                          </div>
                        </div>
                        <div id="changepwd-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="changepwd-alert">Message</div></div>
                        <button id="btn-submit" className="red-button" type="button">Submit</button>
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{clear: "both"}}></div>
        </div>

        <div className="main-content-wrapper">
          <div className="text-content-area">
              <h2 className="text-content-title">Who is SG8 Casino?</h2>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
              <p>Experience the thrill of online gaming at SG8 Casino Philippines, your ultimate destination for a wide range of games. As a PAGCOR-licensed casino, we offer a safe and exciting environment for players, featuring everything from engaging Online Slots and Live Casino experiences to thrilling Sports Betting. Join us for unparalleled entertainment and big wins!</p>
              <div></div>
          </div>
        </div>

      </>
)
}

export default Dashboard